
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');


  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');


  /* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap'); */


  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

.home-d1{
    background-image: url('../../assets/tyagibg.jpg');

    height: 80vh;
    background-position: center;
    background-size: cover;
    padding: 100px 200px;
    align-items: center;
    display: flex;

}

.home-d-2{
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}
.home-c-h1{
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    height: auto;
    color: rgb(255, 255, 255);

    letter-spacing: 11px;
    font-weight: 800;
    font-size: 45px;

    visibility: visible;



    text-transform: uppercase;
}

.home-d-3>h1{
    font-size: 25px;
    text-transform: uppercase;
}

.home-d-4{
 
}

.join-btn-home1{
    border: 1px solid;
    color: white;
    padding: 12px 34px;
    text-transform: capitalize;
    border-radius: 2px; 
}



.custom-btn {
    width: 149px;
    height: 48px;
    padding: 10px 25px;
    border: 2px solid  white;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
  }
  

  .btn-4 {
    position: relative;
    color: white;
    z-index: 2;
    line-height: 40px;
    padding: 0;
  }
  .btn-4:hover{
    border: none;
  }
  .btn-4:before,
  .btn-4:after {
    position: absolute;
    content: "";
    width: 0%;
    height: 0%;
    border: 2px solid;
    z-index: -1;
    transition: all 0.3s ease;
  }
  .btn-4:before {
    top: 0;
     left: 0;
     border-bottom-color: transparent;
     border-right-color: transparent;
     border-top-color: white;
     border-left-color: white;
  }
  .btn-4:after{
     bottom: 0;
     right: 0;
     border-top-color: transparent;
     border-left-color: transparent;
     border-bottom-color: white;
     border-right-color: white;
  }
  .btn-4:hover:before,
  .btn-4:hover:after {
    border-color: white;
    height: 100%;
    width: 100%;
  }
  

  .hc2-d1{
    margin: 5px 0px;

  }
  
.hc2-d2{
  background-image: url('https://images.unsplash.com/photo-1645362841580-965e3171912b?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position: center;
  background-size: cover;
  /* height: 30vh; */
  /* background-color: black; */
  padding: 70px;
}

.hc2-d3{
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.hc2-d3>img{
  width: 90px;
}

.hc2-h1{

  
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
}

.hc2-d4{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.hc3-d1{
  background-color:  black;
  color: white;
  padding: 70px;

}

.hc3-d2{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

}

.lh-hc3-h1{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 40px;
}
.lh-hc3-h2{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 20px;
}
.lh-hc3-h3{
  text-transform: uppercase;
  font-size: 15px;
}

.lh-hc3{

  display: flex;
  flex-direction: column;
  gap: 20px;
}
.btn-hc3-1{
  color: white;
  border: 1px solid ;
  padding: 14px;
   border-radius: 50px;
   transition: all 0.2s ease-out ;
font-size: 15px;
}

.btn-hc3-1:hover{
  background-color: white;
  color: black;
}
.rh-hc3{

}

.rh-hc3>img{
width: 400px;
border-radius: 5px;
}
.hc4-1{
width: 100%;
display: flex;
justify-content: center;
}

.hc4-2{
  padding: 50px;
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;

}
.hc4-lh1{
/* width: 50%; */
width: 500px;

}
.hc4-rh1{
 
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.hc4-d2>h1{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 40px;
}
.hc4-d2>p{

  text-transform: uppercase;
  font-size: 15px;
}

.hc4-d2>img{
width: 500px;
border-radius: 5px;
}

.hc4-d2{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.insta-post-1{

  width: 100%;
  height: 100%;
}

.img-grid-hc41>img{
  width: 300px;
  height: 300px;
  border-radius: 5px;
}

.img-grid-hc42{
 display: flex;
 gap: 10px;
 flex-direction: column;
}

.img-grid-hc42>img{
  width: 150px;
  height: 150px;
  border-radius: 5px;
}

.hc5img-grid-1{
  width: 150px;
  height: 150px;
  border-radius: 5px;
  object-fit: cover;
}

.img-grid-hc43{

}
.hc5img-grid-2{
  width: 300px;
  height: 310px;
  border-radius: 5px;
  object-fit: cover;
}



.hc4-rh2{
  display: flex;
  gap: 10px;

}
.hc4-rh3{
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.insta-dp{

display: flex;
}
.img-dp-insta>img{
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: white;
  padding: 3px;
}
.img-dp-insta{
  background: linear-gradient(45deg,#fa9b57 0,#f7763f 35%,#e34876 55%,#dc327c 75%,#c73390 100%);
  display: flex;
  border-radius: 50px;
  padding: 3px;
  cursor: pointer;
}

.insta-head-name {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.user-name-insta{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  cursor: pointer;
}

.insta-user-name>p{
  text-transform: uppercase;
  font-size: 12px;

}

.span-1-hc4{
  color: gray;
  font-weight: bold;
}

.a-tag-df{
  display: flex;
}
.img-grid-hc4{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hc5-1{
  padding: 50px;
  background-color: black;
  color: white;
}

.hc5-2{

}
.hc5-3{

}

.hc5-h1{
 text-align: center;
 text-transform: uppercase;
 font-size: 30px;
}
.hc5-h2{

  
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 40px;
 text-align: center;

}




.card-price-1{
  width: 350px;
  border: 1px solid  rgba(211, 211, 211, 0.317);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  
}

.card-price-img{
  width: 350px;
  height: 350px;
  object-fit: cover;
}

.plan-card-head{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 25px;
   text-align: center;
}
.card-price-d3{
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.cards-points{



text-align: center;


}

.cards-points>h1{
  font-size: 17px;
  align-items: center;  
}

.jn-btn-price{
  background-color: transparent;
  border: 1px solid lightgrey;
  color: white;
  padding: 10px 30px;
  border-radius: 5px;
}

.jn-btn-price:hover{
background-color: white;
color: black;
}


.btn-d1-jn{
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;

}
.card-price-d6{
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.pricing-card-hc5 {
  display: flex;
  justify-content: center;
  padding: 58px 0px;
  flex-wrap: wrap;
  gap: 15px;
}




.hc-6-2{
  background-color: black;
  color: white;
  padding: 50px;
  margin: 5px 0px;
}

.hc-6-1{
  /* margin: 50px 0px; */
}

.hc-6-h1{

  
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 40px;
  text-align: center;
}
.bmr-bmi-form-d1{
width: 100%;
padding: 50px 0px;
display: flex;
justify-content: center;
}

.bmr-d1{
  width: 80%;
  display: flex;
  padding: 0px 0px 50px 0px;
  justify-content: space-around;
  align-items: center;
}

.form-rh{
width: 500px;
}


.bmr-head-h-p>h1{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 65px;
}
.bmr-head-h-p>p{
  color: gray;
  font-size: 15px;
}


.div-input-bmr{
display: flex;
justify-content: space-between;
}

.input-bmr-div{
width: 48%;
display: flex;
}
.input-bmr-div>input{
  width: 100%;
  padding: 10px;
  white-space: nowrap;
}

.input-bmr-div>select{
  width: 100%;
  padding: 10px;
  white-space: nowrap;
}


.form-bmr{
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.form-lh {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.btn-cal-1{

  background: black;
  color: white;
  padding: 14px 50px;
  border-radius: 5px;
}


.btn-cal-1:hover{

  background: gray;
  transition: all 0.3s ease;

}



.bmr-result1{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bmr-result2{
  
}
.bmr-result3>h3{
  font-size: 16px;
}

/* App.css */

.table-container {
  margin: 20px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
}

.styled-table th,
.styled-table td {
  /* border-bottom: 1px solid #ddd; */
  padding: 8px;
  text-align: left;
  padding: 17px;
}

.styled-table th {

  font-weight: bold;
}

.styled-table td:nth-child(1),
.styled-table th:nth-child(1),
.styled-table td:nth-child(1),
.styled-table th:nth-child(1) {
  border-right: 1px solid #ddd; /* Add border between Name and Age columns */
}



.styled-table th,
.styled-table tr {
  border-bottom: 1px solid #ddd;

}
.table-rh-h1{
  color: rgba(128, 128, 128, 0.709);
  font-size: 15px;
  text-align: center;
}

.table-rh-h1>span{
color: black;
}

.testimonials-img-div{

}



.test-d1-hc7{
  width: 100%;
  justify-content: center;
  display: flex;
  margin: 50px 0px;
}

.test-d2-hc7{
  width: 80%;

}
.img-test-div1{
  
}


.img-test-hc{

}

.test-img{
  width: 300px ;
  height: 300px;
  object-fit: cover;
}

.slide-box-section{

}
.img-testslide {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}


#slideshowAnim{
  background-color: rgb(21 21 21 / 93%) !important;
  opacity: 1;
}
.hc-7-2 {
  background-color: black;
  color: white;
  padding: 50px;

}

.hc-8-1{

}


.hc-8-2{
  width: 100%;
  display: flex;
  justify-content: center;
}

.hc-8-3{
  width: 80%;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
}



.hc-8-4{
  background-color: black;
  color: white;
  padding: 50px;
  margin: 5px 0px;
  display: flex;
   flex-direction: column;
   gap: 10px;
}

.hc-8-4>h1{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 40px;
  text-align: center;



  
}

.hc-8-4>p{
  text-align: center;
font-size: 20px;
font-family: 'Roboto Condensed', sans-serif;
text-transform: uppercase;
}


.hc-8-2{

}

.hc-8-lh>h1{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;

  font-size: 55px;
  line-height: .9em;
  letter-spacing: .02em;

  font-weight: 800;
}

.hc-8-lh>p{
  color: gray;
  font-size: 16px;
}

.hc-8-lh{
  display: flex;
  flex-direction: column;
  gap: 20px;

}.hc-8-5{
  display: flex;
 
 
}

.hc-8-rh>textarea{
width: 100%;
padding: 4px;
}

.hc-8-rh>button{
  background: black;
  padding: 15px 40px;
  color: white;
  border-radius: 5px;
}
.hc-8-rh{

  
  display: flex;
  gap: 20px;
   align-items: start;
}

.hc-8-rh>button:hover{
  background: white;

  color: black;
  border: 1px solid ;

}


@media (max-width:1220px){
  .hc4-2 {

    width: 95%;
    display: flex;

}

.bmr-d1 {
  width: 95%;
  display: flex;

}
.test-d2-hc7 {
  width: 95%;

}
.img-testslide {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.hc3-d1 {
  background-color: black;
  color: white;
  padding: 70px 30px;
}
}

@media (max-width:1080px){

  .hc4-2 {
  
    display: flex;
    flex-direction: column;
}
.hc4-d2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.hc4-lh1 {
  /* width: 50%; */
  width: 100%;
}


.hc4-rh1{
 
  width: 100%;

}

.hc4-rh1 {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  padding: 20px 0px;
}
.bmr-d1 {
  width: 95%;
  display: flex;
  flex-direction: column;
}
.form-lh {
  width: 100%;
 
}
.form-rh {
  width: 100%;
}

.home-d1 {
  
  height: 80vh;
  background-position: center;
  background-size: cover;
  padding:  unset;
  align-items: center;
  display: flex;
  justify-content: center;
}

}


@media (max-width:600px){
  .hc4-2 {
    padding: 50px 0px;

}
.hc2-d4 {
  display: flex;

  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}
.hc4-d2>img {
  width: 271px;
  border-radius: 5px;
}

.hc4-rh2 {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.img-grid-hc42 {
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.hc5-1 {
  padding: 20px;
  background-color: black;
  color: white;
}
.card-price-1 {
  width: 100%;

}
.card-price-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.hc-8-4>h1 {

  font-size: 19px;

}
.hc-8-4>p{

  font-size: 19px;

}
.hc-8-lh>h1 {
  
  font-size: 30px;

}
.hc-8-3 {
  width: 95%;

}

.home-c-h1 {
font-size: 24px;

}
.home-d-3>h1 {
  font-size: 15px;
  text-transform: uppercase;
}
.hc-6-h1 {

  font-size: 20px;

}
.bmr-head-h-p>h1 {

  font-size: 34px;
}
}