@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
*{
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}



ul{
  list-style: none;
}


button {
  background: transparent;
  border: none;
  cursor: pointer;
}
a{
   text-decoration: none;
   color: black;
}