.nav-links{

}
.div-nav2{
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;
  color: white;
    align-items: center;

}

.nav-ul1{
    display: flex;
    gap: 20px;
}
.nav-ul1>li{
    cursor: pointer;
    font-size: 15px;
}
.logo-nav-h1{
    font-size: 30px;
}

.div-nav-1{
position: absolute;
width: 100%;
}